import { observable, action } from "mobx";
import moment from "moment";

class ReportStore {
    @observable filterType = "monthly";

    @observable startDate = moment().startOf("month");
    @observable endDate = moment().endOf("month");

    @observable organisationId = undefined;
    @observable stateId = undefined;
    @observable regionId = undefined;
    @observable venueId = undefined;
    @observable actId = undefined;
    @observable creatorId = undefined;
    @observable statusFilter = "all"
    @observable invoiceStatusFilter = [
        "Pending",
        "Outstanding",
        "Paid",
        "Overdue"
    ];
    @observable bookingType = undefined

    @action
    setCreatorId(creatorId){{
        this.creatorId = creatorId
    }}

    @action
    resetFilterData() {
        // this.filterType = "monthly";
        // this.startDate = moment().startOf("month");
        // this.endDate = moment().endOf("month");
        // this.organisationId = undefined;
        // this.stateId = undefined;
        // this.regionId = undefined;
        // this.venueId = undefined;
        // this.actId = undefined;
        // this.statusFilter = "all";
        // this.invoiceStatusFilter = [
        //     "Pending",
        //     "Outstanding",
        //     "Paid",
        //     "Overdue"
        // ];
    }

    @action
    resetFilterDataOnLogout() {
        this.filterType = "monthly";
        this.startDate = moment().startOf("month");
        this.endDate = moment().endOf("month");
        this.organisationId = undefined;
        this.stateId = undefined;
        this.regionId = undefined;
        this.venueId = undefined;
        this.actId = undefined;
        this.creatorId = undefined;
        this.statusFilter = "all";
        this.bookingType = undefined
        this.invoiceStatusFilter = [
            "Pending",
            "Outstanding",
            "Paid",
            "Overdue"
        ];
    }

    @action
    initialFilter(){
        // console.log(localStorage.getItem("startDateInvoice"))
        // if(localStorage.getItem("startDateInvoice")){
        //     this.startDate =  localStorage.getItem("startDateInvoice");
        // }
      
        // if(localStorage.getItem("endDateInvoice")){
        //     this.endDate =  localStorage.getItem("endDateInvoice");
        // }
        // if(localStorage.getItem("invoiceStatusFilter")){
        //     this.invoiceStatusFilter = localStorage.getItem("invoiceStatusFilter");
        // }
        // if(localStorage.getItem("organizationInvoice")){
        //     this.organisationId = localStorage.getItem("organizationInvoice");
        // }if(localStorage.getItem("stateInvoice")){
        //     this.stateId = localStorage.getItem("stateInvoice");
        // } if(localStorage.getItem("regionInvoice")){
        //     this.regionId = localStorage.getItem("regionInvoice");
        // } if(localStorage.getItem("venueInvoice")){
        //     this.venueId = localStorage.getItem("venueInvoice");
        // }if(localStorage.getItem("statusInvoice")){
        //     this.statusFilter = localStorage.getItem("statusInvoice");
        // }if(localStorage.getItem("actInvoice")){
        //     this.actId = localStorage.getItem("actInvoice");
        // }
        
       
      
       
        
        
        
    }

    @action
    clearDateRangeFilter() {
        this.filterType = "custom";
        this.startDate = undefined;
        this.endDate = undefined;
    }

    @action
    setFilterType(value) {
        this.filterType = value;
        switch (this.filterType) {
            case "all":
                this.startDate = undefined;
                this.endDate = undefined;
                break;
            case "today":
                this.startDate = moment().startOf("day");
                this.endDate = moment().endOf("day");
                break;
            case "lastweek":
                this.startDate = moment().subtract(1, 'weeks').startOf("isoWeek");
                this.endDate = moment().subtract(1, 'weeks').endOf("isoWeek").subtract(1,"days");
                break;
            case "week":
                this.startDate = moment().startOf("isoWeek");
                this.endDate = moment().endOf("isoWeek").subtract(1,"days");
                break;
            case "month":
                this.startDate = moment().startOf("month");
                this.endDate = moment().endOf("month");
                break;
            case "monthly":
                this.startDate = moment().startOf("month");
                this.endDate = moment().endOf("month");
                break;
            case "nextMonth":
                this.startDate = moment()
                    .add(1, "M")
                    .startOf("month");
                this.endDate = moment()
                    .add(1, "M")
                    .endOf("month");
                break;
            default:
                break;
        }
    }

    @action
    setStartDate(value) {
        this.startDate = value;
    }

    @action
    setEndDate(value) {
        this.endDate = value;
    }

    @action
    setOrganisationFilter(orgId) {
        
        this.organisationId = orgId !== 0 ? orgId : undefined;
    }

    @action
    setStateFilter(stateId) {
        this.stateId = stateId !== 0 ? stateId : undefined;
    }

    @action
    setRegionFilter(regionId) {
        this.regionId = regionId !== 0 ? regionId : undefined;
    }

    @action
    setVenueFilter(venueId) {
        this.venueId = venueId !== 0 ? venueId : undefined;
    }

    @action
    setStatusFilter(status){
        this.statusFilter = status;
    }

    @action
    setInvoiceStatusFilter(value){
        if(value.length > 0){
            this.invoiceStatusFilter = value;
        }
        else{
            this.invoiceStatusFilter = [
                "Pending",
                "Outstanding",
                "Paid",
                "Overdue"
            ];
        }
    }

    @action
    setActFilter(actId){
      
        this.actId = actId !== 0 ? actId : undefined;
    }

    @action
    setBookingType(value){
      
        this.bookingType = value
    }
    
}

export default new ReportStore();
