import { observable, action } from "mobx";
import moment from "moment";

class AgentFilterStore {
    @observable filterType = "monthly";
    @observable startDate = moment().startOf("month");
    @observable endDate = moment().endOf("month");

    @observable venueFilter = 0;
    @observable actFilter = 0;
    @observable bookingType = undefined

    @action
    resetFilterData() {
        // this.filterType = "month";
        // this.startDate = moment().startOf("month");
        // this.endDate = moment().endOf("month");
        // this.organisationId = undefined;
        // this.stateId = undefined;
        // this.regionId = undefined;
        // this.venueId = undefined;
    }

    @action
    clearDateRangeFilter() {
        this.filterType = "custom";
        this.startDate = undefined;
        this.endDate = undefined;
    }

    @action
    setFilterType(value) {
        this.filterType = value;
        switch (this.filterType) {
            case "all":
                this.startDate = undefined;
                this.endDate = undefined;
                break;
            case "lastweek":
                this.startDate = moment().subtract(1, 'weeks').startOf("isoWeek");
                this.endDate = moment().subtract(1, 'weeks').endOf("isoWeek").subtract(1,"days");
                break;
            case "today":
                this.startDate = moment().startOf("day");
                this.endDate = moment().endOf("day");
                break;
            case "week":
                this.startDate = moment().startOf("isoWeek");
                this.endDate = moment().endOf("isoWeek").subtract(1,"days");
                break;
            case "monthly":
                this.startDate = moment().startOf("month");
                this.endDate = moment().endOf("month");
                break;
            case "month":
                this.startDate = moment().startOf("month");
                this.endDate = moment().endOf("month");
                break;
            case "nextMonth":
                this.startDate = moment()
                    .add(1, "M")
                    .startOf("month");
                this.endDate = moment()
                    .add(1, "M")
                    .endOf("month");
                break;
            default:
                break;
        }
    }

    @action
    setStartDate(value) {
        this.startDate = value;
    }

    @action
    setEndDate(value) {
        this.endDate = value;
    }

    @action
    setVenueFilter(venueId) {
        this.venueFilter = venueId; // !== 0 ? venueId : undefined;
    }

    @action
    setActFilter(actId) {
        this.actFilter = actId;
    }

    @action
    setBookingType(value) {
        this.bookingType = value;
    }
    
}

export default new AgentFilterStore();
