import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Heading from "../../components/Heading";
import moment from "moment";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import { Button, Grid, Avatar, Tooltip, Typography, TextField, IconButton, Chip, Paper, LinearProgress, Input,Table } from "@material-ui/core";
import RichTextEditor from "../../components/richTextEditor/RichTextEditor";
import Breadcrumb from "../../components/Breadcrumb";
import initials from "initials";
import "./MessageDetail.css";
import ProfileSearchBox from "../../components/fields/ProfileSearchBox";
import blankProfile from "../../img/hotgig-loading.png";
import PageNotFound from "../../pages/profile/PageNotFound";
import EditIcon from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ProfileType } from "../../types/enum";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmationModal from "../../components/modal/ConfirmationModal.js";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

@inject("invoiceStore", "templateStore", "profileStore", "commonStore", "conversationStore", "agentConsultantStore", "bookingRequestStore")
@observer
class MessageDetail extends Component {
    
    state = {
        participantArr: [],
        isEmpty : true,
        isPageBlank : false,
        resetEditor: false,
        fromPublic: false,
        publicProfile: {},
        resetMessageChat: false,
        titleOn: false,
        showEditTitle: false,
        messageId: 0,
        messageDeleteModal : false
      };
    
    loadConversation = id => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.conversationStore.loadById(id).then(response=>{
            if(response){
                this.setState({participantArr :[]})
                this.props.conversationStore.setTitle(response.subject);

                for(var i=0;i<response.conversationParticipants.length;i++){
                    if(response.conversationParticipants[i].profile.id!=currentProfile.id){
                        let participantProfile = response.conversationParticipants[i].profile
                        participantProfile.fullName = participantProfile.type == ProfileType.Agent.ordinal && participantProfile.profileName ? participantProfile.profileName : participantProfile.firstName+" "+participantProfile.lastName
                        participantProfile.firstName = participantProfile.firstName
                        participantProfile.lastName = participantProfile.lastName
                        participantProfile.id = participantProfile.id
                        this.setState({
                            participantArr : [...this.state.participantArr, response.conversationParticipants[i].profile]
                        })
                    }
                }

                // if message from public profile
                if (response.messages.length > 0 && response.messages[0].isFromEmail) {
                    if(response.conversationParticipants.length && response.conversationParticipants[0]){
                        this.setState({fromPublic: true, publicProfile: response.conversationParticipants[0].profile})
                    }else{
                        this.setState({fromPublic: true, publicProfile: response.messages[0]})
                    }
                    
                }
            }else{
                this.setState({isPageBlank :true})
            }
  
        }); 
    };

    componentDidMount() {
        // this.myRef = this.refs.editor;
        this.props.conversationStore.setMessageChats([])
        if(this.props.match.params.conversationId  > 0){
            this.props.conversationStore.setMessage("<p></p>")
            this.loadConversation(this.props.match.params.conversationId);
            this.props.conversationStore.setMessageId(this.props.match.params.conversationId)
            this.setState({showEditTitle: true})
        }else if(this.props.match.params.conversationId === "invoice"){
            this.setState({titleOn: true})
            this.props.conversationStore.setMessageId(0)
            this.setState({
                participantArr : this.props.conversationStore.participant
            })
        }
        else {
            this.props.conversationStore.setMessageId(0)
            this.props.conversationStore.setParticipant([]);
            this.props.conversationStore.setMessageChats([]);
            this.props.conversationStore.setTitle("");
            this.props.conversationStore.setMessage("<p></p>");
            this.setState({titleOn: true})
        }

        // receive props from public profile
        if (this.props.location.state?.profile) {
            const participant = [this.props.location.state.profile]
            this.props.conversationStore.setParticipant(participant)
            this.setState({participantArr: participant, titleOn: true})
        }
    }

    handleTitleChange = value => {
        if (this.state.titleOn == true) {
            this.props.conversationStore.setTitle(value);
            this.checkValidation()
        } 
    }

    handleMessageChange = value => {
        this.setState({...this.state, resetEditor: false})
        this.props.conversationStore.setMessage(value)
        this.checkValidation()
    }
    // clientFirstName: "",
    // clientLastName: "",
    // clientAddress: "",
    // clientEmail: "",
    // clientPhone: "",
    handleConfirmCreatePrivateBooking = () => {
        const {
            conversation
        } = this.props.conversationStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        var currentData = null
        if(conversation && conversation.metadata){
            currentData = JSON.parse(conversation.metadata)
            currentData.fromFirstName = this.state?.publicProfile?.firstName
            currentData.fromLastName = this.state?.publicProfile?.lastName
            currentData.fromEmail = this.state?.publicProfile?.email
            currentData.timeStart = moment(currentData.timeStart)
            currentData.timeEnd = moment(currentData.timeEnd)
        }
        this.props.bookingRequestStore.setPrivateHireInitialData(currentData)
        this.props.history.push(`/privateBooking?isPrivate=1&privatehireid=${conversation.createdById}&act=${currentData.actId}`);
    };
    
    handleSendMessage = () => {
        this.setState({...this.state, resetMessageChat: true, isEmpty: true})
        if(this.props.match.params.conversationId  > 0){
            this.props.conversationStore.submitMessage().then(response => {
                this.props.conversationStore.setMessageId(response.id)
                this.props.conversationStore.setMessage("<p></p>");
                this.setState({...this.state, resetEditor: true, resetMessageChat: false})
            });
        }else{
            this.props.conversationStore.setParticipant(this.state.participantArr)
            this.props.conversationStore.submit().then(response => {
                this.props.conversationStore.setMessageId(response.id)
                this.props.conversationStore.setMessage("<p></p>");
                this.setState({...this.state, resetEditor: true, resetMessageChat: false})
                this.props.history.push(`/messages/${response.id}`)
                this.loadConversation(response.id)
            });
        }  
    };

    checkValidation = () =>{
        if(this.props.conversationStore.body!="<p></p>" && this.props.conversationStore.title!="" && this.state.participantArr.length>0 || this.state.fromPublic){
            this.setState({isEmpty: false})
        }else{
            this.setState({isEmpty: true})
        }
    }

    handleAddParticipants = (participant) => {
        if(!participant.fullName.includes("No User Found")){
            if (participant.type == ProfileType.Agent.ordinal) {
                participant.fullName = participant.profileName
            } 
            if(this.props.match.params.conversationId  > 0){
                this.props.conversationStore.addParticipant(this.props.match.params.conversationId,participant)
            }
            this.state.participantArr.push(participant)
            this.checkValidation()
        }
    };

    handleDelete = (item) => {
        if(this.props.match.params.conversationId  > 0){
            this.props.conversationStore.removeParticipant(this.props.match.params.conversationId, item)
        }
        
        for(var i=0; i<this.state.participantArr.length;i++){
            if(this.state.participantArr[i].id==item.id){
                this.state.participantArr.splice(i,1)
                break
            }
        }
        this.checkValidation()
    };

    handleChangeSubject = () => {
        if (this.state.titleOn == false && this.props.match.params.conversationId) {
            this.setState({titleOn: true})
        } else if (this.state.titleOn == true && this.props.match.params.conversationId) {
            this.props.conversationStore.changeSubjectConversation()
            this.setState({titleOn: false})
        } else {
            this.setState({titleOn: !this.state.titleOn})
        }
    }


    handleOpenModal = (id) => {
        this.setState({ messageId: id, messageDeleteModal : true });
    }

    handleCloseModal = () => {
        this.setState({ messageId: 0, messageDeleteModal : false });
    }

    handleDelete = () => {
        const { messageChats } = this.props.conversationStore;
        this.props.conversationStore.deleteMessage(this.state.messageId).then(() =>{
            var filteredMessages = messageChats.filter(x=>x.id != this.state.messageId)
            this.props.conversationStore.setMessages(filteredMessages);
            this.handleCloseModal()
        })
        
    }
    render() {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        const { messageChats } = this.props.conversationStore;
        
        const {
            title,
            conversation,
            messages,
            body,
            firstName,
            lastName,
            metadataBody,
            eventType,
            eventDate,
            email,
            phone,
            actName,
            timeStart,
            timeEnd,
            actId,
            isLoading
        } = this.props.conversationStore;
        const breadcrumb = [
            { name: "Dashboard", link: "/" },
            { name: "Messages", link: "/messages" },
            { name: (conversation && conversation.subject) || "Detail" }
        ];
        var currentData = null
        if(conversation && conversation.metadata){
            currentData = JSON.parse(conversation.metadata)
            currentData.fromEmail = this.state?.publicProfile?.email
        }  
      
        return (
            this.state.isPageBlank ? (
                <PageNotFound />
            ) : (

                <Fragment>
                    <Header />
                    <Sidebar />
                    <MainContainer>
                        <Breadcrumb links={breadcrumb} />
                        <Fragment>
                            <Heading title={title ? title : "New Message"} />
                            <Paper style={{ padding: 16}}>
                                <Grid container>
                                    {!this.state.fromPublic ? 
                                        <Fragment>
                                            <Grid item xs={12} lg={3}>
                                                <div style={{ marginTop: 9}}>
                                                    <Typography variant="headline" component="h6" gutterBottom>
                                                        Conversation Title
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} lg={9}>  
                                                <TextField 
                                                    onChange = {e=> this.handleTitleChange(e.target.value)}
                                                    value={this.state.titleOn ? title : ""} 
                                                    fullWidth
                                                    placeholder={this.state.titleOn ? "Conversation Title" : title}
                                                    InputProps={{
                                                        endAdornment: (
                                                            this.state.showEditTitle ? 
                                                            <Button  variant={this.state.titleOn ? "contained" : ""} color="" className="" onClick={this.handleChangeSubject}>
                                                                <EditIcon/>
                                                            </Button> : null
                                                        )
                                                    }}
                                                    margin="dense" />
                                            </Grid>
                                        </Fragment>    :
                                        // <Fragment>
                                        //     <Grid item xs={12} lg={3}>
                                        //         <div style={{ marginTop: 9}}>
                                        //             <Typography variant="headline" component="h6" gutterBottom>
                                        //                 From
                                        //             </Typography>
                                        //         </div>
                                        //     </Grid>
                                        //     <Grid item xs={12} lg={9}>  
                                        //         <TextField 
                                        //             value={this.state?.publicProfile?.fullName } 
                                        //             fullWidth 
                                        //             margin="dense" />
                                        //     </Grid>
                                        // </Fragment>
                                        null
                                    }


                                    { !this.state.fromPublic ? 
                                        <Fragment>
                                            <Grid item xs={12} lg={3}>
                                                <div>
                                                    <Typography variant="headline" component="h6" gutterBottom>
                                                        Participants
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} lg={9} style={{ marginBottom: '1em' }}>
                                                
                                            <div className="participant-list">
                                                {this.state.participantArr.map(item => (
                                                    <div className="tag-item" key={item.id}>
                                                        {item.fullName && item.type !== ProfileType.Staff.ordinal ? item.fullName : item.firstName + " " + item.lastName}
                                                        <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() => this.handleDelete(item)}
                                                        >
                                                        &times;
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                                <ProfileSearchBox
                                                    height="auto"
                                                    label="Participants"
                                                    id="search-profile"
                                                    disableInvite
                                                    placeholder="Add to Conversation"
                                                    handleSearch={value => this.props.profileStore.searchAllProfiles(value)}
                                                    handleChange={item => this.handleAddParticipants(item)}
                                                    exclude={this.state.participantArr}
                                                />
                                            </Grid>
                                        </Fragment> : null
                                    }

                                    { this.state.fromPublic ? 
                                        <Fragment>
                                            <Grid item xs={12} lg={3}>
                                                <div>
                                                    <Typography variant="headline" component="h6" gutterBottom>
                                                        Participants
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} lg={9} style={{ marginBottom: '1em' }}>
                                                
                                            <div className="participant-list">
                                                {this.state.participantArr.map(item => (
                                                    <div className="tag-item" style={{paddingRight: "15px"}} key={item.id}>
                                                        {item.fullName && item.type !== ProfileType.Staff.ordinal && item.type !== ProfileType.PrivateHire.ordinal ? item.fullName : item.firstName + " " + item.lastName}
                                                     
                                                    </div>
                                                ))}
                                            </div>
                                            </Grid>
                                        </Fragment> : null
                                    }

                                    <Grid item xs={12} lg={3}></Grid>
                                    <Grid item xs={12} lg={9} style={{ marginBottom: '1em'}}>
                                           {this.state.fromPublic ? 
                                        <Fragment>
                                            <Grid item xs={12} lg={3}>
                                                <div style={{ marginTop: 9}}>
                                                    <Typography variant="headline" component="h6" gutterBottom>
                                                        Message
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            
                                            <Grid item xs={12} lg={12}>  
                                                <div className={messageChats[0].profileId === currentProfile.id ? "chat me" : "chat"} style={{marginTop:"1rem"}}>
                                              
                                                <Avatar src={`${messageChats[0].profileImageUrl === null || typeof messageChats[0].profileImageUrl === "undefined" ? blankProfile : messageChats[0].profileImageUrl}`} className="chat-avatar" />
                                                    <div className="chat-message">
                                                        <Tooltip
                                                            title={`Time: ${moment(messageChats[0].createdAt)
                                                            .local()
                                                            .format("DD-MM-YYYY HH:mm:ss")}`}
                                                            >
                                                            <p className="chat-name">{this.state?.publicProfile?.firstName} {this.state?.publicProfile?.lastName}</p>
                                                        </Tooltip>
                                                        {/* <div dangerouslySetInnerHTML={{ __html: `${messageChats[0].body}` }}></div> */}
                                                        <table style={{border:"1px solid black"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{textAlign:"right",padding:"5px"}}>Email</td>
                                                                    <td style={{border:"1px solid black",padding:"5px"}}>{currentData.fromEmail}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{textAlign:"right",padding:"5px"}}>Phone</td>
                                                                    <td style={{border:"1px solid black",padding:"5px"}}>{currentData.fromPhone}</td>                        
                                                                </tr>
                                                                <tr>
                                                                    <td style={{textAlign:"right",padding:"5px"}}>Event Type</td>
                                                                    <td style={{border:"1px solid black",padding:"5px"}}>{currentData.eventType}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{textAlign:"right",padding:"5px"}}>Act</td>
                                                                    <td style={{border:"1px solid black",padding:"5px"}}>{currentData.actName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{textAlign:"right",padding:"5px"}}>Event Date</td>
                                                                    <td style={{border:"1px solid black",padding:"5px"}}>
                                                                         {moment(currentData.eventDate).format("ddd, DD-MM-YYYY")} {moment(currentData.timeStart).format("h.mma")} - {moment(currentData.timeEnd).format("h.mma")}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Fragment> :null
                                    }
                                        <Grid container>
                                            {messageChats && messageChats.map(mess => (
                                                <Grid item xs={12} >
                                                    {mess.profileId && (
                                                        <div className={mess.profileId === currentProfile.id ? "chat me" : "chat"}>
                                                            <Avatar src={`${mess.profileImageUrl === null || typeof mess.profileImageUrl === "undefined" ? blankProfile : mess.profileImageUrl}`} className="chat-avatar" />

                                                            {
                                                    mess.profileId === currentProfile.id ? 
                                                    <IconButton
                                                            aria-label="close"
                                                            style={{    position: "absolute",
                                                                color: "#525252",
                                                                right: 33,
                                                                fontSize: 15,
                                                                marginTop: "33px"}}
                                                                onClick={() => this.handleOpenModal(mess.id)}
                                                            >
                                                    <FontAwesomeIcon icon={['fas', 'trash-can']} />  
                                                    </IconButton>: null
                                                }
                                                
                                                            <div className="chat-message">
                                                                <Tooltip
                                                                    title={`Time: ${moment(mess.createdAt)
                                                                        .local()
                                                                        .format("DD-MM-YYYY HH:mm:ss")}`}
                                                                >
                                                                    <p className="chat-name">{mess.profile.type == ProfileType.Agent.ordinal && mess.profile.profileName ? mess.profile.profileName : mess.profile.firstName+" "+mess.profile.lastName}</p>
                                                                </Tooltip>
                                                                <div dangerouslySetInnerHTML={{ __html: `${mess.body}` }}></div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {!mess.profileId && (
                                                        !this.state.fromPublic ? 
                                                            <div className="chat-notif">
                                                                <div className="chat-notif-message">
                                                                    <div dangerouslySetInnerHTML={{ __html: `${mess.body}` }}></div>
                                                                </div>
                                                            </div> : null
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>

                                 

                                    {!this.state.fromPublic ? 
                                        <Fragment>
                                            <Grid item xs={12} lg={3}>
                                                <div>
                                                    <Typography variant="headline" component="h6" gutterBottom>
                                                        Your Message
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} lg={9}>
                                                {!this.state.resetMessageChat ? 
                                                    <RichTextEditor
                                                        // inputRef={ref => this.bodyMessage = ref}
                                                        // ref={this.myRef}
                                                        label="Message"
                                                        placeholder="Type a message here"
                                                        value={body}
                                                        onChange={this.handleMessageChange}
                                                        resetEditor={this.state.resetEditor}
                                                    />
                                                : null}
                                                <br />
                                                <LinearProgress variant={isLoading ? "indeterminate" : "determinate"} />
                                                <br />
                                                <div style={{ textAlign: "right" }}>
                                                    <Button disabled={this.state.isEmpty ? true : false} type="submit" variant="contained" color="primary" onClick={this.handleSendMessage}>
                                                        Send
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Fragment> : 
                                        <Fragment>
                                            <Grid item xs={12} lg={3}>
                                                <div>
                                                    <Typography variant="headline" component="h6" gutterBottom>
                                                        Your Message
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} lg={9}>
                                                {!this.state.resetMessageChat ? 
                                                    <RichTextEditor
                                                        // inputRef={ref => this.bodyMessage = ref}
                                                        // ref={this.myRef}
                                                        label="Message"
                                                        placeholder="Type a message here"
                                                        value={body}
                                                        onChange={this.handleMessageChange}
                                                        resetEditor={this.state.resetEditor}
                                                    />
                                                : null}
                                                <br />
                                                <LinearProgress variant={isLoading ? "indeterminate" : "determinate"} />
                                                <br />
                                                <div style={{ padding:10,textAlign: "right" }}>
                                                {conversation.createdById !== currentProfile.id ?  <Button type="submit" style={{ margin:5}} variant="contained" color="secondary" onClick={this.handleConfirmCreatePrivateBooking}>
                                                        Confirm and Create Functional Booking
                                                    </Button> : null
    }
                                                   
                                                    <Button disabled={this.state.isEmpty ? true : false} type="submit" variant="contained" color="primary" onClick={this.handleSendMessage}>
                                                        Send
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Fragment>    
                                    }
                                </Grid>
                            </Paper>
                        </Fragment>
                    </MainContainer>
                    <ConfirmationModal
                                    open={this.state.messageDeleteModal}
                                    title="Delete negotiate message"
                                    message={`Are you sure to delete this message? `}
                                    closeHandler={this.handleCloseModal}
                                    confirmationHandler={() => this.handleDelete()}
                                    declineHandler={this.handleCloseModal}
                                    confirmationLabel="Delete"
                                />
                </Fragment>
            )
        );
    }
}

export default MessageDetail;
