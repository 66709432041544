/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:b2e05ac1-5766-40f3-97cf-42970c37f38a",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Zz0dZlPtX",
    "aws_user_pools_web_client_id": "hq4n1d0jetalv7hcv02ljmtrf",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "hotgiggity-be",
            "endpoint": "https://xebzg693ji.execute-api.ap-southeast-2.amazonaws.com/develop",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "hotgiggitybackend29d007dbf85b471eb62f7ef7768ca3171534-hgdev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};

export default awsmobile;