import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";

import { Grid, InputLabel, Dialog, AppBar, Toolbar, Typography, Button, FormControlLabel, Switch } from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

import RichTextEditor from "../../richTextEditor/RichTextEditor";
import { ProfileType } from "../../../types/enum";

// import { BookingStatusType } from "../../../types/enum";

// import moment from "moment";

@inject("bookingRequestStore", "profileStore", "agentVenueStore", "agentActStore")
@observer
class BookingNotes extends Component {
    state = {
        contractFullState: false
    };

    handleNotesChange = e => {
        this.props.bookingRequestStore.setBooking("notes", e);
    };

    handleOpenContract = () => {
        this.setState({
            ...this.state,
            contractFullState: true
        });
    };

    handleCloseContract = () => {
        this.setState({
            ...this.state,
            contractFullState: false
        });
    };

    handleSaveRequirementsTemplateChange = (e, checked) => {
        this.props.bookingRequestStore.setBooking("saveNotesAsTemplate", checked);
        this.setState({
            ...this.state
        });
    };

    transition = props => {
        return <Slide direction="up" {...props} />;
    };

    render() {
        const { notes, saveNotesAsTemplate } = this.props.bookingRequestStore.booking;
        const {isNoteChange, noteIsChanging} = this.props
        const { currentProfile } = this.props.profileStore;
        const isStaff = currentProfile && currentProfile.type === ProfileType.Staff.ordinal;
        const isAgent = currentProfile && currentProfile.type === ProfileType.Agent.ordinal;
        // let confirmedEditable =
        //     status !== BookingStatusType.Cancelled.ordinal &&
        //     status !== BookingStatusType.Completed.ordinal &&
        //     moment(dateStart).endOf("day") >= moment() &&
        //     (!createdById || createdById === currentProfile.id);
        const readonly = this.props.readOnly;

        return (
            <Fragment>
                <Grid container spacing={8} className="">
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            className=""
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <InputLabel>{ this.props.isPrivateBooking ? "Act Notes" : "Notes"}</InputLabel>
                            {!readonly && 
                                <Button color="primary" size="small" onClick={this.handleOpenContract} className="button-small fullscreen-bio">
                                    <FullscreenIcon />
                                    <Typography>Fullscreen</Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {readonly ? 
                                <Typography style={{border:"2px solid #E0E0E0", padding:5, borderRadius:5}}><div dangerouslySetInnerHTML={{__html: notes}} style={{minHeight:50}}></div> </Typography> :
                                <RichTextEditor id="booking-notes-rte" value={notes} onChange={this.handleNotesChange} readOnly={readonly} isVenueChange={isNoteChange} resetEditor={this.state.contractFullState} onChangeEditor={noteIsChanging} />
                            }
                        </Grid>
                        {(isStaff || isAgent) && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={saveNotesAsTemplate}
                                        onChange={this.handleSaveRequirementsTemplateChange}
                                        disabled={readonly}
                                    />
                                }
                                label="Save as Notes Template"
                            />
                        )}
                    </Grid>
                </Grid>
                <Dialog fullScreen open={this.state.contractFullState} onClose={this.handleCloseContract} transition={this.transition}>
                    <AppBar style={{ position: "relative" }}>
                        <Toolbar>
                            <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                Notes
                            </Typography>
                            <Button color="inherit" onClick={this.handleCloseContract}>
                                Exit Fullscreen
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="fullscreen-rich-text">
                        {readonly ? 
                            <Typography ><div dangerouslySetInnerHTML={{__html: notes}}></div> </Typography> :   
                            <RichTextEditor value={notes} onChange={this.handleNotesChange} readOnly={readonly} resetEditor={isNoteChange} onChangeEditor={noteIsChanging} />
                        }
                    </div>
                </Dialog>
            </Fragment>
        );
    }
}

export default BookingNotes;
