import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Button, Grid, Typography, Dialog, AppBar, Toolbar } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import EditIcon from "@material-ui/icons/Edit";
import ModalContainer from "../modal/ModalContainer";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import textSample from "./SampleTermCondition";
import RichTextEditor from "../richTextEditor/RichTextEditor";
import Loading from "../loadingSpinner/Loading";
import { ProfileType } from "../../types/enum";

@inject("profileStore", "templateStore")
@withRouter
@observer
class TermCondition extends React.Component {
    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.profileStore
            .loadTermCondition(this.props.profileId)
            .then(term => {
                this.props.profileStore.setTermCondition(term.termCondition);
                this.props.profileStore.setClientContract(term.clientContract);
                this.props.profileStore.setNotes(term.notes);
                this.props.profileStore.setRequirements(term.requirements);
            })
            .finally(_ => {
                this.setState({ isLoading: false });
            });
            this.props.profileStore.getProfile(this.props.profileId)
            .then(profile => {
                if (!this.props.profileStore.isCreateVenue) {
                    this.props.profileStore.setVenueNameTitle("Edit Venue - " + (profile.profileName ? profile.profileName : ""))
                }
            })
        
    }

    state = {
        dialogOpen: false,
        dialogContractOpen: false,
        dialogNotesOpen: false,
        dialogRequirementsOpen: false,
        openSampleTerms: false,
        openSampleContract: false,
        openSampleNotes: false,
        openSampleRequirements: false,
        sample: false,
        sampleContract: false,
        isLoading: true,
        resetEditor: false,
    };

    handleFullscreenTermDialogOpen = () => {
        this.setState({ dialogOpen: true });
    };

    handleFullscreenTermDialogClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleFullscreenContractDialogOpen = () => {
        this.setState({ dialogContractOpen: true });
    };

    handleFullscreenContractDialogClose = () => {
        this.setState({ dialogContractOpen: false });
    };

    handleFullscreenNotesDialogOpen = () => {
        this.setState({ dialogNotesOpen: true });
    };

    handleFullscreenNotesDialogClose = () => {
        this.setState({ dialogNotesOpen: false });
    };

    handleFullscreenRequirementsDialogOpen = () => {
        this.setState({ dialogRequirementsOpen: true });
    };

    handleFullscreenRequirementsDialogClose = () => {
        this.setState({ dialogRequirementsOpen: false });
    };

    handleSampleTermDialogOpen = () => {
        this.setState({ openSampleTerms: true });
    };

    handleSampleTermDialogClose = () => {
        this.setState({ openSampleTerms: false });
    };

    handleSampleContractDialogOpen = () => {
        this.setState({ openSampleContract: true });
    };

    handleSampleContractDialogClose = () => {
        this.setState({ openSampleContract: false });
    };

    handleSampleNotesDialogOpen = () => {
        this.setState({ openSampleNotes: true });
    };

    handleSampleNotesDialogClose = () => {
        this.setState({ openSampleNotes: false });
    };

    handleSampleRequirementsDialogOpen = () => {
        this.setState({ openSampleRequirements: true });
    };

    handleSampleRequirementsDialogClose = () => {
        this.setState({ openSampleRequirements: false });
    };

    handleSampleTerms = () => {
        this.setState({ openSampleTerms: false, sample: textSample }, () => {
            this.props.profileStore.setTermCondition(textSample);
            this.setState({ sample: false, resetEditor: true });
        });
    };

    handleSampleContract = () => {
        this.setState({ openSampleContract: false, sampleContract: textSample }, () => {
            this.props.profileStore.setClientContract(textSample);
            this.setState({ sampleContract: false, resetEditor: true });
        });
    };

    handleSampleNotes = () => {
        this.setState({ openSampleNotes: false, sampleNotes: textSample }, () => {
            this.props.profileStore.setNotes(textSample);
            this.setState({ sampleNotes: false, resetEditor: true });
        });
    };

    handleSampleRequirements = () => {
        this.setState({ openSampleRequirements: false, sampleRequirements: textSample }, () => {
            this.props.profileStore.setRequirements(textSample);
            this.setState({ sampleRequirements: false, resetEditor: true });
        });
    };

    handleBack = () => {
        if (this.props.type === "venue") {
            const { profile } = this.props.profileStore;
            this.props.history.push(`/venues/${profile.id}/edit/menu`);
        }
        if (this.props.type === "act") {
            this.props.history.replace(`/myact/edit/${this.props.match.params.id}/setlist`);
        }
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        this.setState({ isLoading: true });
        this.props.profileStore.createTermCondition().then(_ => {
            this.setState({ isLoading: false });
            if (this.props.type === "venue") {
                this.props.history.push(`/venues/${this.props.profileId}/edit/privacy`);
            }
            if (this.props.type === "act") {
                this.props.history.push(`/myact/edit/${this.props.profileId}/privacy`);
            }
            if (this.props.type !== "act" && this.props.type !== "venue") {
                const { editProfileActiveStep } = this.props.templateStore;
                this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
            }
        });
    };

    anyError(field) {
        if (!this.props.authStore.errors) return false;
        if (!this.props.authStore.errors[field]) return false;
        return true;
    }

    errorText(field) {
        if (!this.props.authStore.errors) return null;
        if (!this.props.authStore.errors[field]) return null;
        return this.props.authStore.errors[field];
    }

    handleTermConditionChange = e => {
        this.setState({...this.state, resetEditor: false})
        this.props.profileStore.setTermCondition(e);
    };

    handleClientContractChange = e => {
        this.setState({...this.state, resetEditor: false})
        this.props.profileStore.setClientContract(e);
    };

    handleNotesChange = e => {
        this.setState({...this.state, resetEditor: false})
        this.props.profileStore.setNotes(e);
    };
    handleRequirementsChange = e => {
        this.setState({...this.state, resetEditor: false})
        this.props.profileStore.setRequirements(e);
    };

    render() {
        const { editProfileActiveStep } = this.props.templateStore;
        const { termCondition, clientContract, notes, requirements } = this.props.profileStore;
        const type = this.props.type;
        const steps = this.props.templateStore.getSteps(type);
        const { isLoading } = this.state;
        const { currentProfile } = this.props.profileStore;

        const isAgent = currentProfile && currentProfile.type === ProfileType.Agent.ordinal;
        const isStaff = currentProfile && currentProfile.type === ProfileType.Staff.ordinal;
        // const isMusician = currentProfile && currentProfile.type === ProfileType.Musician.ordinal;

        const showTermsAndCondition = isStaff || isAgent || type==="agent";
        const showNotes = isStaff;
        const showRequirements = isStaff;
        const showClientContract = true;

        return (
            <div className="Profile-Privacy">
                <form onSubmit={this.handleSubmitForm} className="form-relative">
                    <Loading showed={isLoading} />
                    <Grid container spacing={24}>
                        {showTermsAndCondition && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Act Contract
                                        </Typography>
                                        <Typography gutterBottom>
                                            {this.props.type !== "venue" ? "This will be used as the template terms and conditions when you or your agents create a new booking." : "This contract will be added to each booking at this venue, created either by venue staff or an agent."}
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={9}>
                                    <Grid container spacing={8}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "baseline"
                                            }}
                                        >
                                            <Button color="primary" size="small" onClick={this.handleSampleTermDialogOpen} className="button-small">
                                                <EditIcon />
                                                <Typography color="primary">Open Sample Terms and Conditions</Typography>
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={this.handleFullscreenTermDialogOpen}
                                                className="button-small"
                                            >
                                                <FullscreenIcon />
                                                <Typography style={{color:"#fff", textTransform:"none"}}>Fullscreen</Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                        {
                                                    !isLoading && <RichTextEditor
                                                        id="rte-terms-condition"
                                                        value={termCondition}
                                                        onChange={this.handleTermConditionChange}
                                                        sample={this.state.sample}
                                                        resetEditor={this.state.resetEditor || this.state.dialogOpen}
                                                />
                                                }
                                        </Grid>
                                        <Dialog
                                            fullScreen
                                            open={this.state.dialogOpen}
                                            onClose={this.handleFullscreenTermDialogClose}
                                            transition={this.transition}
                                            className="dialog-div"
                                        >
                                            <AppBar style={{ position: "relative" }}>
                                                <Toolbar>
                                                    <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                                        Terms And Conditions
                                                    </Typography>
                                                    <Button color="inherit" onClick={this.handleFullscreenTermDialogClose}>
                                                        Exit Fullscreen
                                                    </Button>
                                                </Toolbar>
                                            </AppBar>
                                            <div className="fullscreen-rich-text">
                                                {
                                                    !isLoading && <RichTextEditor
                                                        id="rte-terms-condition-modal"
                                                        value={termCondition}
                                                        onChange={this.handleTermConditionChange}
                                                        sample={this.state.sample}
                                                        resetEditor={this.state.resetEditor}
                                                />
                                                }
                                            </div>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}

                        {showClientContract &&  this.props.type !== "venue" && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Client Contract
                                        </Typography>
                                        <Typography gutterBottom>
                                            When you create a functional booking, this will be used as the default client contract.
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={9}>
                                    <Grid container spacing={8}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "baseline"
                                            }}
                                        >
                                            <Button color="primary" size="small" onClick={this.handleSampleContractDialogOpen} className="button-small">
                                                <EditIcon />
                                                <Typography color="primary">Open Sample Contract</Typography>
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={this.handleFullscreenContractDialogOpen}
                                                className="button-small"
                                            >
                                                <FullscreenIcon />
                                                <Typography style={{color:"#fff", textTransform:"none"}}>Fullscreen</Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                !isLoading &&
                                                <RichTextEditor
                                                    id="rte-client-contract"
                                                    value={clientContract}
                                                    onChange={this.handleClientContractChange}
                                                    sample={this.state.sampleContract}
                                                    resetEditor={this.state.resetEditor || this.state.dialogContractOpen}
                                                />
                                            }
                                        </Grid>
                                        <Dialog
                                            fullScreen
                                            open={this.state.dialogContractOpen}
                                            onClose={this.handleFullscreenContractDialogClose}
                                            transition={this.transition}
                                            className="dialog-div"
                                        >
                                            <AppBar style={{ position: "relative" }}>
                                                <Toolbar>
                                                    <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                                        Client Contract
                                                    </Typography>
                                                    <Button color="inherit" onClick={this.handleFullscreenContractDialogClose}>
                                                        Exit Fullscreen
                                                    </Button>
                                                </Toolbar>
                                            </AppBar>
                                            <div className="fullscreen-rich-text">
                                                {
                                                    !isLoading &&
                                                    <RichTextEditor
                                                        id="rte-client-contract-modal"
                                                        value={clientContract}
                                                        onChange={this.handleClientContractChange}
                                                        sample={this.state.sampleContract}
                                                        resetEditor={this.state.resetEditor}
                                                    />
                                                }
                                            </div>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        {showNotes && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Notes
                                        </Typography>
                                        <Typography gutterBottom>
                                        {this.props.type !== "venue" ? "When you create a functional booking, this will be used as the default notes." : "The notes section can be used to inform the artist about additional information for the booking including load in location, parking, DB limits etc, and will be added to each booking at this venue, created either by venue staff or an agent."}
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={9}>
                                    <Grid container spacing={8}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "baseline"
                                            }}
                                        >
                                            <Typography gutterBottom>Notes</Typography>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={this.handleFullscreenNotesDialogOpen}
                                                className="button-small"
                                            >
                                                <FullscreenIcon />
                                                <Typography style={{color:"#fff", textTransform:"none"}}>Fullscreen</Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                !isLoading &&
                                                <RichTextEditor
                                                    id="rte-notes"
                                                    value={notes}
                                                    onChange={this.handleNotesChange}
                                                    sample={this.state.sampleNotes}
                                                    resetEditor={this.state.resetEditor || this.state.dialogNotesOpen}
                                                />
                                            }
                                        </Grid>
                                        <Dialog
                                            fullScreen
                                            open={this.state.dialogNotesOpen}
                                            onClose={this.handleFullscreenNotesDialogClose}
                                            transition={this.transition}
                                            className="dialog-div"
                                        >
                                            <AppBar style={{ position: "relative" }}>
                                                <Toolbar>
                                                    <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                                        Notes
                                                    </Typography>
                                                    <Button color="inherit" onClick={this.handleFullscreenNotesDialogClose}>
                                                        Exit Fullscreen
                                                    </Button>
                                                </Toolbar>
                                            </AppBar>
                                            <div className="fullscreen-rich-text">
                                                {
                                                    !isLoading &&
                                                    <RichTextEditor
                                                        id="rte-notes-modal"
                                                        value={notes}
                                                        onChange={this.handleNotesChange}
                                                        sample={this.state.sampleNotes}
                                                        resetEditor={this.state.resetEditor}
                                                    />
                                                }
                                            </div>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        {showRequirements && (
                            <Fragment>
                                <Grid item xs={12} lg={3}>
                                    <div className="sticky-information">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Production Requirements
                                        </Typography>
                                        <Typography gutterBottom>
                                            {this.props.type !== "venue" ? "When you create a functional booking, this will be used as the default production requirements." : "The production requires can be used to inform the artist or sound engineers about production supplied by the venue, as well as equipment required to be supplied by the artist."}
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={9}>
                                    <Grid container spacing={8}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "baseline"
                                            }}
                                        >
                                            <Typography gutterBottom>Production Requirements</Typography>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={this.handleFullscreenRequirementsDialogOpen}
                                                className="button-small"
                                            >
                                                <FullscreenIcon />
                                                <Typography style={{color:"#fff", textTransform:"none"}}>Fullscreen</Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                !isLoading &&
                                                <RichTextEditor
                                                    id="rte-requirements"
                                                    value={requirements}
                                                    onChange={this.handleRequirementsChange}
                                                    sample={this.state.sampleRequirements}
                                                    resetEditor={this.state.resetEditor || this.state.dialogRequirementsOpen}
                                                />
                                            }
                                        </Grid>

                                        <Dialog
                                            fullScreen
                                            open={this.state.dialogRequirementsOpen}
                                            onClose={this.handleFullscreenRequirementsDialogClose}
                                            transition={this.transition}
                                            className="dialog-div"
                                        >
                                            <AppBar style={{ position: "relative" }}>
                                                <Toolbar>
                                                    <Typography variant="title" color="inherit" style={{ flex: 1 }}>
                                                        Production Requirements
                                                    </Typography>
                                                    <Button color="inherit" onClick={this.handleFullscreenRequirementsDialogClose}>
                                                        Exit Fullscreen
                                                    </Button>
                                                </Toolbar>
                                            </AppBar>
                                            <div className="fullscreen-rich-text">
                                                {
                                                    !isLoading &&
                                                    <RichTextEditor
                                                        id="rte-requirements-modal"
                                                        value={requirements}
                                                        onChange={this.handleRequirementsChange}
                                                        sample={this.state.sampleRequirements}
                                                        resetEditor={this.state.resetEditor}
                                                    />
                                                }
                                            </div>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        <Grid item xs={12}>
                            <div className="action">
                                <Button disabled={editProfileActiveStep === 0} onClick={this.handleBack}>
                                    Back
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    {editProfileActiveStep === steps.length - 1 ? "Save/Finish" : "Save/Next"}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
                <ModalContainer open={this.state.openSampleTerms} title="Sample Terms and Conditions" onClose={this.handleSampleTermDialogClose}>
                    <DialogContent>
                        <div
                            style={{
                                border: "1px solid",
                                padding: "18px",
                                textAlign: "justify",
                                marginTop: "20px"
                            }}
                            dangerouslySetInnerHTML={{ __html: textSample }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSampleTermDialogClose}>Close</Button>
                        <Button color="primary" onClick={this.handleSampleTerms}>
                            Use This Sample Terms and Conditions
                        </Button>
                    </DialogActions>
                </ModalContainer>
                <ModalContainer open={this.state.openSampleContract} title="Sample Contract" onClose={this.handleSampleContractDialogClose}>
                    <DialogContent>
                        <div
                            style={{
                                border: "1px solid",
                                padding: "18px",
                                textAlign: "justify",
                                marginTop: "20px"
                            }}
                            dangerouslySetInnerHTML={{ __html: textSample }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSampleContractDialogClose}>Close</Button>
                        <Button color="primary" onClick={this.handleSampleContract}>
                            Use This Sample Contract
                        </Button>
                    </DialogActions>
                </ModalContainer>
                <ModalContainer open={this.state.openSampleNotes} title="Sample Notes" onClose={this.handleSampleNotesDialogClose}>
                    <DialogContent>
                        <div
                            style={{
                                border: "1px solid",
                                padding: "18px",
                                textAlign: "justify",
                                marginTop: "20px"
                            }}
                            dangerouslySetInnerHTML={{ __html: textSample }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSampleNotesDialogClose}>Close</Button>
                        <Button color="primary" onClick={this.handleSampleNotes}>
                            Use This Sample Notes
                        </Button>
                    </DialogActions>
                </ModalContainer>
                <ModalContainer
                    open={this.state.openSampleRequirements}
                    title="Sample Requirements"
                    onClose={this.handleSampleRequirementsDialogClose}
                >
                    <DialogContent>
                        <div
                            style={{
                                border: "1px solid",
                                padding: "18px",
                                textAlign: "justify",
                                marginTop: "20px"
                            }}
                            dangerouslySetInnerHTML={{ __html: textSample }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSampleRequirementsDialogClose}>Close</Button>
                        <Button color="primary" onClick={this.handleSampleRequirements}>
                            Use This Sample Production Requirements
                        </Button>
                    </DialogActions>
                </ModalContainer>
            </div>
        );
    }
}

export default TermCondition;
